import React, { useState } from "react";
import "./Detail.css";
import event1 from "./../assets/164304.jpg";
import Navbar from './../components/Navbar';
function Detail() {
  return (
    <>
      <Navbar/>
      <div className="main-contains">
        <div className="inner-contains">
          <div className="d-flex detail-contain">
            <div className="event-contains shadow rounded-2">
              <img src={event1} className="event-imgs" alt="Hard Rock Stadium" />
              <div className="event-detail">
                <p className="member-titles ms-4 mt-4">Texans vs Dolphins</p>
                <div className="member-date ms-4 d-flex mb-3">
                  <div>
                    <span className="me-4">
                      <svg width="16" height="16" viewBox="0 0 16 16">
                        <g fill="none" fillRule="evenodd">
                          <path d="M0 0h16v16H0z"></path>
                          <path
                            fill="#262626"
                            fillOpacity=".65"
                            fillRule="nonzero"
                            d="M15 5V3.5a.5.5 0 0 0-.5-.5h-1.585a1.5 1.5 0 0 1-2.83 0h-4.17a1.5 1.5 0 0 1-2.83 0H1.5a.5.5 0 0 0-.5.5V5h14zm0 1H1v8.5a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5V6zm-5-4v-.5a1.5 1.5 0 0 1 3 0V2h1.5A1.5 1.5 0 0 1 16 3.5v11a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-11A1.5 1.5 0 0 1 1.5 2H3v-.5a1.5 1.5 0 0 1 3 0V2h4zM3 7h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1zm1.5-4a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-1 0v1a.5.5 0 0 0 .5.5zm7 0a.5.5 0 0 0 .5-.5v-1a.5.5 0 1 0-1 0v1a.5.5 0 0 0 .5.5zM3 8v2h2V8H3z"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </div>
                  <div className="mt-1">
                    <span>Sun • Nov 07, 2021 • 01:00 PM</span>
                  </div>
                </div>
                <div className="member-date ms-4 d-flex mb-3">
                  <div>
                    <svg
                      className="ms-1 me-4"
                      width="12"
                      height="16"
                      viewBox="0 0 12 16"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        transform="translate(-2)"
                      >
                        <path d="M0 0h16v16H0z"></path>
                        <path
                          stroke="#262626"
                          strokeLinejoin="round"
                          d="M8 15.5c3.667-4 5.5-7.167 5.5-9.5C13.5 2.5 11 .5 8 .5S2.5 2.5 2.5 6c0 2.333 1.833 5.5 5.5 9.5z"
                          opacity=".65"
                        ></path>
                        <circle
                          cx="8"
                          cy="6"
                          r="2.5"
                          stroke="#262626"
                          opacity=".65"
                        ></circle>
                      </g>
                    </svg>
                  </div>
                  <div className="mt-1">
                    <span>Miami, FL — Hard Rock Stadium</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column ticket-block">
              <div className="d-flex ticket">
                <div className="round-block">
                  <svg className="desktop" width="24" height="24">
                    <defs>
                      <clipPath id="a">
                        <path
                          d="M14.5 3a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5h-3a.51.51 0 0 1-.5-.5.5.5 0 0 1 .5-.5zm-5 0a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5h-1a.51.51 0 0 1-.5-.5.5.5 0 0 1 .5-.5zm.7 6H8v3h8V9h-2.2a2.11 2.11 0 0 1-1.8 1 2.13 2.13 0 0 1-1.8-1zm5.8 4H8v7h8zm.5-12h-9A1.5 1.5 0 0 0 6 2.49V20h1V8.49A.5.5 0 0 1 7.5 8h3a.5.5 0 0 1 .45.27l.05.11A1.12 1.12 0 0 0 12 9a1.11 1.11 0 0 0 1-.62l.05-.11A.5.5 0 0 1 13.5 8h3a.5.5 0 0 1 .5.5V20h1V2.49A1.5 1.5 0 0 0 16.5 1zM18 21H6v.5A1.5 1.5 0 0 0 7.5 23h9a1.5 1.5 0 0 0 1.5-1.5zM16.5 0A2.5 2.5 0 0 1 19 2.49v19A2.5 2.5 0 0 1 16.5 24h-9A2.5 2.5 0 0 1 5 21.49v-19A2.5 2.5 0 0 1 7.5 0z"
                          fill="none"
                        ></path>
                      </clipPath>
                    </defs>
                    <g clipPath="url(#a)">
                      <path fill="#904eba" d="M0 0h24v24H0z"></path>
                    </g>
                  </svg>
                </div>
                <div className="d-flex flex-column ms-4">
                  <div className="ticket-title">Your Phone Is Your Ticket</div>
                  <div className="ticket-detail">
                    For the fastest way in, add your ticket to your digital
                    wallet. (Heads up, this ticket won’t have a barcode.)
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between safetix">
                <div>
                  <h3 className="title">My SafeTix™</h3>
                </div>
                <div className="d-flex">
                  <div className="d-flex flex-column">
                    <div className="icon ms-3">
                      <svg width="22" height="24" viewBox="0 0 22 24">
                        <path
                          className="primary"
                          fill="#008e97"
                          fillOpacity=".65"
                          fillRule="evenodd"
                          d="M18.65 6.375A3.544 3.544 0 0 0 20.086 3.5c0-1.933-1.499-3.5-3.348-3.5-1.849 0-3.348 1.567-3.348 3.5 0 1.19.569 2.242 1.437 2.874-1.96.799-3.35 2.791-3.35 5.126v3.895c0 .28.205.558.463.624l1.45.373.957 7.608h4.782l.957-7.608 1.45-.373c.256-.066.463-.344.463-.624V11.5c0-2.332-1.392-4.325-3.35-5.125zM15.208 23l-.87-6.74a.985.985 0 0 0-.72-.84l-1.184-.304V11.5c0-2.487 1.926-4.5 4.304-4.5 2.374 0 4.304 2.017 4.304 4.5v3.616l-1.185.305a.985.985 0 0 0-.72.839L18.27 23h-3.061zm1.53-17c-1.32 0-2.391-1.12-2.391-2.5S15.418 1 16.739 1c1.32 0 2.391 1.12 2.391 2.5S18.06 6 16.74 6zm-11 1v3c0 .433.49.661.798.372l3.73-3.5a.515.515 0 0 0 0-.744l-3.73-3.5c-.308-.289-.798-.06-.798.372v3H.48A.49.49 0 0 0 0 6.5c0 .276.214.5.478.5H5.74zm.957 0V4.12L9.232 6.5 6.696 8.88V7z"
                        ></path>
                      </svg>
                    </div>
                    <div className="icon-title fw-bold">TRANSFER</div>
                  </div>
                  <div className="d-flex flex-column ms-5">
                    <div className="icon">
                      <svg width="25" height="24" viewBox="0 0 25 24">
                        <path
                          fill="#008e97"
                          fillOpacity=".65"
                          fillRule="evenodd"
                          d="M5.205 16.508v3.62c1.667.643 5.11 2.037 5.642 2.232 1.353.496 2.118.637 2.891.522.931-.139 7.157-3.521 9.216-4.655-.857-.77-1.532-1.037-2.118-.835l-3.636 1.34c-.103.826-.828 1.433-1.832 1.433H9.72a.53.53 0 0 1-.52-.54.53.53 0 0 1 .52-.54h5.648c.532 0 .802-.251.802-.557 0-.306-.27-.502-.802-.502h-3.53a.512.512 0 0 1-.372-.162c-.71-.75-1.972-1.356-3.863-1.356H5.205zm0-1.08h2.398c2.046 0 3.523.648 4.445 1.519h3.32c.708 0 1.277.278 1.587.729l3.542-1.305c1.198-.414 2.348.182 3.71 1.593a.55.55 0 0 1-.136.865 144.286 144.286 0 0 0-3.9 2.095c-.574.32-2.374 1.335-2.725 1.53-1.757.973-2.639 1.36-3.56 1.497-.973.145-1.889-.024-3.387-.573-.513-.189-3.545-1.415-5.294-2.097v.447a.53.53 0 0 1-.521.54H.52a.53.53 0 0 1-.521-.54v-6.343a.53.53 0 0 1 .52-.54h4.164a.53.53 0 0 1 .52.54v.043zm-1.042 5.76v-5.263H1.042v5.263h3.121zm0-20.128v7.423H21.86V1.06H4.164zM3.644 0h18.738c.287 0 .52.237.52.53v8.483c0 .293-.233.53-.52.53H3.643a.525.525 0 0 1-.52-.53V.53c0-.293.233-.53.52-.53zm20.315 10.651l-.016-8.097c0-.298.25-.54.537-.54.288 0 .504.242.504.54L25 11.191c0 .298-.233.473-.52.473H6.766c-.288 0-.521-.175-.521-.473a.53.53 0 0 1 .52-.54h17.192zM13.002 6.477c.863 0 1.562-.725 1.562-1.619s-.7-1.62-1.562-1.62c-.864 0-1.563.726-1.563 1.62 0 .894.7 1.62 1.563 1.62zm0 1.08c-1.439 0-2.605-1.208-2.605-2.699 0-1.49 1.166-2.699 2.605-2.699 1.438 0 2.604 1.208 2.604 2.699 0 1.49-1.166 2.699-2.604 2.699z"
                        ></path>
                      </svg>
                    </div>
                    <div className="icon-title fw-bold">SELL</div>
                  </div>
                  <div className="d-flex flex-column ms-5">
                    <div className="round-icon ms-1">
                      <p className="dot">...</p>
                    </div>
                    <div className="icon-title fw-bold">MORE</div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column safetix-table">
                <div className="d-flex justify-content-between border rounded-2 p-4">
                  <div className="d-flex ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ms-4 mt-2"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      style={{ transform: "rotate(45deg)" }}
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M24 0H0v24h24z"></path>
                        <path
                          fill="#262626"
                          fillOpacity=".65"
                          fillRule="nonzero"
                          d="M18.006 4.528a1.03 1.03 0 0 0 1.457 1.457l.332-.332a.5.5 0 0 1 .707 0l3.14 3.14a1 1 0 0 1 0 1.414L10.207 23.642a1 1 0 0 1-1.415 0L.307 15.156a1 1 0 0 1 0-1.414L13.742.307a1 1 0 0 1 1.414 0l3.182 3.182a.5.5 0 0 1 0 .707l-.332.332zM14.45 1.014L1.014 14.45 9.5 22.935 22.935 9.499l-2.786-2.785a2.03 2.03 0 0 1-2.871-2.871l-2.829-2.829zM10.56 4.196a.5.5 0 1 1 .707-.707L13.39 5.61a.5.5 0 1 1-.708.708l-2.12-2.122zM7.732 14.803a.5.5 0 1 1-.707-.707l4.95-4.95a.5.5 0 1 1 .706.707l-4.95 4.95zm4.242 0a.5.5 0 1 1-.707-.707l2.829-2.829a.5.5 0 1 1 .707.707l-2.829 2.829zm2.122-7.071a.5.5 0 1 1 .707-.707l2.121 2.12a.5.5 0 1 1-.707.708l-2.121-2.121zm3.535 3.535a.5.5 0 1 1 .707-.707l2.122 2.121a.5.5 0 1 1-.707.708l-2.122-2.122z"
                        ></path>
                      </g>
                    </svg>
                    <p className="ms-4 fs-5 mb-0 mt-1 fw-bold">
                      Sec 342, Row 25, Seat 15
                    </p>
                  </div>
                  <div>
                    <svg
                      className="evAGUW"
                      viewBox="0 0 15 7"
                      width="16"
                      height="16"
                      fill='function(){var e=0<arguments.length&amp;&amp;void 0!==arguments[0]?arguments[0]:{},n=e.theme,r=(n=void 0===n?se:n).themeName,o=void 0===r?se.themeName:r,a=n.customValues,i=void 0===a?null:a,s=le(o,i);return t.reduce((function(e,t){if(void 0===e[t])throw new ReferenceError("value is not defined");return e[t]}),s)}'
                    >
                      <path d="M13.974.132a.614.614 0 0 0-.762 0L7.17 5.341 1.12.132C.916-.044.563-.037.376.125a.398.398 0 0 0-.167.332c0 .058.012.116.036.17a.385.385 0 0 0 .12.155l6.427 5.54a.568.568 0 0 0 .378.135.568.568 0 0 0 .377-.135l6.427-5.54a.422.422 0 0 0 .156-.325.42.42 0 0 0-.156-.325z"></path>
                    </svg>
                  </div>
                </div>
                <div className="d-flex justify-content-between border border-top-0 p-4">
                  <div className="d-flex ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ms-4 mt-2"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      style={{ transform: "rotate(45deg)" }}
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M24 0H0v24h24z"></path>
                        <path
                          fill="#262626"
                          fillOpacity=".65"
                          fillRule="nonzero"
                          d="M18.006 4.528a1.03 1.03 0 0 0 1.457 1.457l.332-.332a.5.5 0 0 1 .707 0l3.14 3.14a1 1 0 0 1 0 1.414L10.207 23.642a1 1 0 0 1-1.415 0L.307 15.156a1 1 0 0 1 0-1.414L13.742.307a1 1 0 0 1 1.414 0l3.182 3.182a.5.5 0 0 1 0 .707l-.332.332zM14.45 1.014L1.014 14.45 9.5 22.935 22.935 9.499l-2.786-2.785a2.03 2.03 0 0 1-2.871-2.871l-2.829-2.829zM10.56 4.196a.5.5 0 1 1 .707-.707L13.39 5.61a.5.5 0 1 1-.708.708l-2.12-2.122zM7.732 14.803a.5.5 0 1 1-.707-.707l4.95-4.95a.5.5 0 1 1 .706.707l-4.95 4.95zm4.242 0a.5.5 0 1 1-.707-.707l2.829-2.829a.5.5 0 1 1 .707.707l-2.829 2.829zm2.122-7.071a.5.5 0 1 1 .707-.707l2.121 2.12a.5.5 0 1 1-.707.708l-2.121-2.121zm3.535 3.535a.5.5 0 1 1 .707-.707l2.122 2.121a.5.5 0 1 1-.707.708l-2.122-2.122z"
                        ></path>
                      </g>
                    </svg>
                    <p className="ms-4 fs-5 mb-0 mt-1 fw-bold">
                      Sec 342, Row 25, Seat 16
                    </p>
                  </div>
                  <div>
                    <svg
                      className="evAGUW"
                      viewBox="0 0 15 7"
                      width="16"
                      height="16"
                      fill='function(){var e=0<arguments.length&amp;&amp;void 0!==arguments[0]?arguments[0]:{},n=e.theme,r=(n=void 0===n?se:n).themeName,o=void 0===r?se.themeName:r,a=n.customValues,i=void 0===a?null:a,s=le(o,i);return t.reduce((function(e,t){if(void 0===e[t])throw new ReferenceError("value is not defined");return e[t]}),s)}'
                    >
                      <path d="M13.974.132a.614.614 0 0 0-.762 0L7.17 5.341 1.12.132C.916-.044.563-.037.376.125a.398.398 0 0 0-.167.332c0 .058.012.116.036.17a.385.385 0 0 0 .12.155l6.427 5.54a.568.568 0 0 0 .378.135.568.568 0 0 0 .377-.135l6.427-5.54a.422.422 0 0 0 .156-.325.42.42 0 0 0-.156-.325z"></path>
                    </svg>
                  </div>
                </div>
                <div className="d-flex justify-content-between border border-top-0 p-4">
                  <div className="d-flex ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ms-4 mt-2"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      style={{ transform: "rotate(45deg)" }}
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M24 0H0v24h24z"></path>
                        <path
                          fill="#262626"
                          fillOpacity=".65"
                          fillRule="nonzero"
                          d="M18.006 4.528a1.03 1.03 0 0 0 1.457 1.457l.332-.332a.5.5 0 0 1 .707 0l3.14 3.14a1 1 0 0 1 0 1.414L10.207 23.642a1 1 0 0 1-1.415 0L.307 15.156a1 1 0 0 1 0-1.414L13.742.307a1 1 0 0 1 1.414 0l3.182 3.182a.5.5 0 0 1 0 .707l-.332.332zM14.45 1.014L1.014 14.45 9.5 22.935 22.935 9.499l-2.786-2.785a2.03 2.03 0 0 1-2.871-2.871l-2.829-2.829zM10.56 4.196a.5.5 0 1 1 .707-.707L13.39 5.61a.5.5 0 1 1-.708.708l-2.12-2.122zM7.732 14.803a.5.5 0 1 1-.707-.707l4.95-4.95a.5.5 0 1 1 .706.707l-4.95 4.95zm4.242 0a.5.5 0 1 1-.707-.707l2.829-2.829a.5.5 0 1 1 .707.707l-2.829 2.829zm2.122-7.071a.5.5 0 1 1 .707-.707l2.121 2.12a.5.5 0 1 1-.707.708l-2.121-2.121zm3.535 3.535a.5.5 0 1 1 .707-.707l2.122 2.121a.5.5 0 1 1-.707.708l-2.122-2.122z"
                        ></path>
                      </g>
                    </svg>
                    <div>
                      <p className="ms-4 fs-4 mb-0 mt-0 fw-bold">
                        Sec 342, Row 25, Seat 17
                      </p>
                      <p className="ms-4 fs-5 mb-0 mt-1">
                        <span className="fw-bold">Transferred:</span>{" "}
                        <span className="claimed">
                          Claimed by Marcellus Nedd
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between border border-top-0 rounded-2 p-4">
                  <div className="d-flex ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ms-4 mt-2"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      style={{ transform: "rotate(45deg)" }}
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M24 0H0v24h24z"></path>
                        <path
                          fill="#262626"
                          fillOpacity=".65"
                          fillRule="nonzero"
                          d="M18.006 4.528a1.03 1.03 0 0 0 1.457 1.457l.332-.332a.5.5 0 0 1 .707 0l3.14 3.14a1 1 0 0 1 0 1.414L10.207 23.642a1 1 0 0 1-1.415 0L.307 15.156a1 1 0 0 1 0-1.414L13.742.307a1 1 0 0 1 1.414 0l3.182 3.182a.5.5 0 0 1 0 .707l-.332.332zM14.45 1.014L1.014 14.45 9.5 22.935 22.935 9.499l-2.786-2.785a2.03 2.03 0 0 1-2.871-2.871l-2.829-2.829zM10.56 4.196a.5.5 0 1 1 .707-.707L13.39 5.61a.5.5 0 1 1-.708.708l-2.12-2.122zM7.732 14.803a.5.5 0 1 1-.707-.707l4.95-4.95a.5.5 0 1 1 .706.707l-4.95 4.95zm4.242 0a.5.5 0 1 1-.707-.707l2.829-2.829a.5.5 0 1 1 .707.707l-2.829 2.829zm2.122-7.071a.5.5 0 1 1 .707-.707l2.121 2.12a.5.5 0 1 1-.707.708l-2.121-2.121zm3.535 3.535a.5.5 0 1 1 .707-.707l2.122 2.121a.5.5 0 1 1-.707.708l-2.122-2.122z"
                        ></path>
                      </g>
                    </svg>
                    <div>
                      <p className="ms-4 fs-4 mb-0 mt-0 fw-bold">
                        Sec 342, Row 25, Seat 18
                      </p>
                      <p className="ms-4 fs-5 mb-0 mt-1">
                        <span className="fw-bold">Transferred:</span>{" "}
                        <span className="claimed">
                          Claimed by Marcellus Nedd
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="foot">
        <div className="footer d-flex justify-content-between border border-bottom-0 mt-5">
          <div className="p-3 foot-fdesc ms-5">
            HARD ROCK STADIUM PRIVACY POLICY // TICKETMASTER PRIVACY POLICY //
            TERMS OF USE
          </div>
          <div className="p-3 foot-sdesc me-5">
            © 1999-2021 Ticketmaster.All rights reserved.
          </div>
        </div>
      </div>
    </>
  );
}

export default Detail;
