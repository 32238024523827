import React, {useEffect, useState} from "react";
import "./Ticket.css";
import leftarrow from "./../assets/chevron-left.svg";
import rightarrow from "./../assets/chevron-right.svg";
import leftarrow1 from "./../assets/chevron-left1.svg";
import android from "./../assets/download1.png";
import ios from "./../assets/download.png";
import Slider from "react-slick";

function Ticket({input}) {
  // console.log('111', input)
  const [device, setDevice] = useState("");
  const [ticketObj, setticketObj] = useState({});
  const [num, setNum] = useState(1);
  const settings = {
    infinite: false,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: (ticketObj.tickets && ticketObj.tickets.length === 1) ? "0px":"24px",
    slidesToScroll: 4,
    afterChange: (a) => {
      // console.log(a+1)
      setNum(a + 1)
      // if (num === 1) {
      //   setNum(1);
      // } else {
      //   setNum(2);
      // }
    },
  };

  useEffect(() => {
    let userAgent = window.navigator.userAgent;

    // Windows Phone must come first because its UA also contains "Android"
    if (/Windows/i.test(userAgent)) {
      setDevice("Windows Phone");
    }

    if (/Android/i.test(userAgent)) {
      setDevice("Android");
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDevice("iOS");
    }
    console.log(device);
    setTimeout(() => {
      setticketObj(input);
    }, 100);

    const script = document.createElement("script");

    script.src = "/pse.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="ticket-body">
      <div className="ticket-nav d-flex" style={{"background-color": input.c1}}>
        <img src={leftarrow} className="ms-4 left-arrow"></img>
        <div className="d-flex flex-column mt-2 ms-3">
          <div className="daqexq">{input.name}</div>
          <div className="daqdate">
            {input.date} • {input.location}
          </div>
        </div>
      </div>
      <div className="slide-block">
        <Slider {...settings}>
          {Object.keys(ticketObj).length == 0 ? (
            <></>
          ) : (
            Object.values(ticketObj.tickets).map((row, index) => {
              return (
                <div className="slide-item" key={index}>
                  <div style={{margin: "10px"}}>
                    <div
                      className="slide-head rounded-bottom"
                      style={{"background-color": ticketObj.tickets[index].vipColor || input.c1}}
                    >
                      <div className="slide-top rounded-bottom d-flex justify-content-between p-2" >
                        {/*<svg*/}
                        {/*  width="20"*/}
                        {/*  height="20"*/}
                        {/*  className="ticket-card"*/}
                        {/*  aria-label="View Details"*/}
                        {/*  viewBox="0 0 24 24"*/}
                        {/*  version="1.1"*/}
                        {/*>*/}
                        {/*  <desc>Created with Sketch.</desc>*/}
                        {/*  <g*/}
                        {/*    id="Icons/Indicators/InfoDot-Outline-24"*/}
                        {/*    stroke="none"*/}
                        {/*    strokeWidth="1"*/}
                        {/*    fill="none"*/}
                        {/*    fillRule="evenodd"*/}
                        {/*  >*/}
                        {/*    <path*/}
                        {/*      d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,7.25 C12.5522847,7.25 13,6.80228475 13,6.25 C13,5.69771525 12.5522847,5.25 12,5.25 C11.4477153,5.25 11,5.69771525 11,6.25 C11,6.80228475 11.4477153,7.25 12,7.25 Z M12,18 L12,18 C12.5522847,18 13,17.5522847 13,17 L13,10 C13,9.44771525 12.5522847,9 12,9 L12,9 C11.4477153,9 11,9.44771525 11,10 L11,17 C11,17.5522847 11.4477153,18 12,18 Z"*/}
                        {/*      id="info"*/}
                        {/*      fill="rgb(155, 43, 43)"*/}
                        {/*      fillRule="nonzero"*/}
                        {/*    ></path>*/}
                        {/*  </g>*/}
                        {/*</svg>*/}
                        <p className="tick-title my-1">{ticketObj.tickets[index].name}</p>
                        <svg
                          width="20"
                          height="20"
                          className="ticket-card"
                          aria-label="View Details"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <desc>Created with Sketch.</desc>
                          <g
                            id="Icons/Indicators/InfoDot-Outline-24"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <path
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,7.25 C12.5522847,7.25 13,6.80228475 13,6.25 C13,5.69771525 12.5522847,5.25 12,5.25 C11.4477153,5.25 11,5.69771525 11,6.25 C11,6.80228475 11.4477153,7.25 12,7.25 Z M12,18 L12,18 C12.5522847,18 13,17.5522847 13,17 L13,10 C13,9.44771525 12.5522847,9 12,9 L12,9 C11.4477153,9 11,9.44771525 11,10 L11,17 C11,17.5522847 11.4477153,18 12,18 Z"
                              id="info"
                              fill="#fff"
                              fillRule="nonzero"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div className="d-flex justify-content-around mt-3">
                        <div className="d-flex flex-column">
                          <div className="fs-4 text-light fw-bold ms-1">
                            Sec
                          </div>
                          <div className="fs-3 text-light fw-bold">{ticketObj.tickets[index].section}</div>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="fs-4 text-light fw-bold">Row</div>
                          <div className="fs-3 text-light fw-bold ms-2">{ticketObj.tickets[index].row}</div>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="fs-4 text-light fw-bold">Seat</div>
                          <div className="fs-3 text-light fw-bold ms-2">{ticketObj.tickets[index].seat}</div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center text-light mt-4 head-text">
                        {ticketObj.tickets[index].gatePortalLabel}
                      </div>
                    </div>
                    <div className="slide-body rounded-top d-flex flex-column align-items-center">
                      {console.log(ticketObj.tickets[index].token)}
                      <BarCode ticketIndex={index} ticketObj={ticketObj.tickets[index].token}/>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p></p>
                      {/*<img*/}
                      {/*  src={device === "Android" ? android : ios}*/}
                      {/*  className={*/}
                      {/*    device === "Android" ? "btn-img" : "btn-imgios"*/}
                      {/*  }*/}
                      {/*  alt="..."*/}
                      {/*/>*/}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </Slider>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <div className="fs-4 mx-4 mt-2">{num} of {input.tickets.length}</div>
      </div>
      {/*<div style={{display: device == "Android" ? "none" : "block"}}>*/}
      {/*  <div className="alerts-box d-flex justify-content-center">*/}
      {/*    <div className="alerts d-flex justify-content-between">*/}
      {/*      <div className="d-flex">*/}
      {/*        <div className="alerts-icon">*/}
      {/*          <svg width="36" height="36" viewBox="0 0 36 36">*/}
      {/*            <title>wallet icon</title>*/}
      {/*            <path*/}
      {/*              d="M27.75 19.5a8.25 8.25 0 1 1 0 16.5 8.25 8.25 0 0 1 0-16.5zm0 1.5a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5zm0 3a.75.75 0 0 1 .738.615l.012.135V27h2.25a.75.75 0 0 1 .738.615l.012.135a.75.75 0 0 1-.615.738l-.135.012H28.5v2.25a.75.75 0 0 1-.615.738l-.135.012a.75.75 0 0 1-.738-.615L27 30.75V28.5h-2.25a.75.75 0 0 1-.738-.615L24 27.75a.75.75 0 0 1 .615-.738L24.75 27H27v-2.25a.75.75 0 0 1 .488-.703l.127-.035.135-.012zm0-24a3.75 3.75 0 0 1 3.75 3.75v13.5a.75.75 0 1 1-1.5 0V13.5h-6.296a4.303 4.303 0 0 0-3.58 1.916l-.252.378a4.954 4.954 0 0 1-8.244 0l-.252-.378a4.303 4.303 0 0 0-3.58-1.916H1.5v6.75c0 2.9 2.35 5.25 5.25 5.25h9a.75.75 0 1 1 0 1.5h-9A6.75 6.75 0 0 1 0 20.25V3.75A3.75 3.75 0 0 1 3.75 0h24zm-3 9h-18a2.25 2.25 0 0 0-2.25 2.25V12h3.296c1.94 0 3.752.97 4.828 2.584l.252.378a3.454 3.454 0 0 0 5.748 0l.252-.378A5.803 5.803 0 0 1 23.704 12H27v-.75A2.25 2.25 0 0 0 24.75 9zm3-7.5h-24A2.25 2.25 0 0 0 1.5 3.75V12H3V6.75A3.75 3.75 0 0 1 6.75 3h18a3.75 3.75 0 0 1 3.75 3.75V12H30V3.75a2.25 2.25 0 0 0-2.25-2.25zm-3 3h-18A2.25 2.25 0 0 0 4.5 6.75v1.5a3.734 3.734 0 0 1 2.25-.75h18c.845 0 1.624.28 2.251.75L27 6.75a2.25 2.25 0 0 0-2.25-2.25z"*/}
      {/*              fill="black"*/}
      {/*              fillRule="nonzero"*/}
      {/*            ></path>*/}
      {/*          </svg>*/}
      {/*        </div>*/}
      {/*        <div className="alerts-text">*/}
      {/*          Add to Apple Wallet and beat slow WiFi on the big day.*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="me-2">*/}
      {/*        <svg*/}
      {/*          width="16"*/}
      {/*          height="16"*/}
      {/*          aria-label="Close"*/}
      {/*          viewBox="0 0 16 16"*/}
      {/*        >*/}
      {/*          <g fill="none" fillRule="evenodd">*/}
      {/*            <path d="M0 0h16v16H0z"></path>*/}
      {/*            <path*/}
      {/*              fill="black"*/}
      {/*              fillOpacity=".8"*/}
      {/*              fillRule="nonzero"*/}
      {/*              d="M8.707 8l6.147 6.146a.5.5 0 0 1-.708.708L8 8.707l-6.146 6.147a.5.5 0 0 1-.708-.708L7.293 8 1.146 1.854a.5.5 0 1 1 .708-.708L8 7.293l6.146-6.147a.5.5 0 0 1 .708.708L8.707 8z"*/}
      {/*            ></path>*/}
      {/*          </g>*/}
      {/*        </svg>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="d-flex justify-content-around btn-block p-4">
        <button type="button" className="btn-trans" style={{"background-color": input.c1}} >
          Transfer
        </button>
        <button type="button" className="btn-trans" style={{"background-color": input.c1}} >
          Sell
        </button>
        <button type="button" className="btn-dot" style={{"background-color": input.c1}} >
          <p className="mb-0 dot-p">...</p>
        </button>
      </div>
    </div>
  );
}

function BarCode({ticketIndex, ticketObj}) {
  let render;

  const loadBarcode = () => {
    var e = new window.Presence.SecureEntryView({});
    render = e;

    e.setToken(ticketObj);
    e.setSelector(`#barcode-inner${ticketIndex}`);
  };
  useEffect(() => {
    setTimeout(() => {
      loadBarcode();
    }, 200);
  }, []);

  return (
    <div data-v-0140e88e="" className="v2-ticket-body">
      <div data-v-0140e88e="" className="barcode-main-container">
        <div data-v-0140e88e="" className="barcode-inner-container">
          <div data-v-0140e88e="" id="barcode-here">
            <div
              data-v-0140e88e=""
              id={`barcode-inner${ticketIndex}`}
              style={{width: "100px", height: "100px"}}
            >
              {render}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ticket;
