import React, {useContext, useEffect, useState} from 'react';// from './logo.svg';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {
  Switch,
  Route,
  BrowserRouter,
  useLocation
} from 'react-router-dom';
import Event from './pages/Event';
import Detail from './pages/Detail'
import Ticket from './pages/Ticket';

import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel'
import {Spinner} from 'react-bootstrap'
import Slider from "react-slick";
// import "slick-carousel";
// import "~slick-carousel/slick/slick-theme.css";


import './App.css';
import './arrow_back_ios_black_24dp.svg';

function App() {

  const [getBarcodeBool, setBarcodeBool] = useState({bool: false})
  const [ticketObj, setticketObj] = useState({})

  useEffect(() => {
    // axios.get('/')
    const ticketIDs = (new URL(window.location)).hash.substring(1)
    // alert(ticketIDs)
    const fetchURL = `https://api.tixbc.com/tmbc/ticket/${ticketIDs}`
    // alert(fetchURL)
    axios.get(fetchURL).then((res) => {
      // alert(res.data)
      // console.log(res.data)
      setticketObj(res.data)
    })
    // console.log(ticketIDs.split(','))
    // const script = document.createElement('script');
    //
    // script.src = "/pse.js";
    // script.async = true;
    //
    // document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // }
  }, []);


  return (<>
    {Object.keys(ticketObj).length && <Ticket input={ticketObj}/>}
  </>)
  // return (
  //   <>
  //     {Object.keys(ticketObj).length == 0 &&
  //     <>
  //       ...
  //     </>
  //     }
  //     {Object.keys(ticketObj).length !== 0 &&
  //     <>
  //       <div className="App">
  //         <div className="">
  //           <div className="tixbcHeader" style={{
  //             // paddingLeft: '24px',
  //             // marginLeft: '24px',
  //             // marginRight: '24px',
  //             margin: 'auto',
  //             backgroundColor: ticketObj.c1,
  //           }}>
  //             <div className="tixbcBackIcon">
  //               {backArrow}
  //             </div>
  //             <div className="tixbcHeaderGameDetails">
  //               <div style={{}}>
  //                 <div
  //                   className="tixbcHeaderGameDetailsGameName">{ticketObj.name}</div>
  //                 <div
  //                   className="tixbcHeaderGameDetailsLoc">{ticketObj.date} - {ticketObj.location}</div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="tixbcTicketBody">
  //             <div>
  //               <Slider {...settings}>
  //                 {Object.values(ticketObj.tickets).map((row, index) => {
  //                   // alert(row.vipColor)
  //                   return (
  //                       <div className="tixbcTicketBody1">
  //                       <div style={{
  //                         backgroundColor: row.vipColor || ticketObj.c1,
  //                         text: 'white',
  //                         // width: '500px',
  //                         marginLeft:'auto',
  //                         marginRight:'auto',
  //                         // paddingRight: '10px',
  //                         // paddingLeft: '10px',
  //                       }}>
  //                         <div className={'ticketHeaderTop'}>
  //                           <span>
  //                             {/*{console.log(row)}*/}
  //                             {row.name || row.ticketName}
  //                           </span>
  //                           <div style={{float: 'right'}}>
  //                             <svg width="20" height="20"
  //                                  className="infoIcon"
  //                                  aria-label="View Details" viewBox="0 0 24 24"
  //                                  version="1.1">
  //                               <desc>Created with Sketch.</desc>
  //                               <g id="Icons/Indicators/InfoDot-Outline-24"
  //                                  stroke="none" stroke-width="1" fill="none"
  //                                  fill-rule="evenodd">
  //                                 <path
  //                                   d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,7.25 C12.5522847,7.25 13,6.80228475 13,6.25 C13,5.69771525 12.5522847,5.25 12,5.25 C11.4477153,5.25 11,5.69771525 11,6.25 C11,6.80228475 11.4477153,7.25 12,7.25 Z M12,18 L12,18 C12.5522847,18 13,17.5522847 13,17 L13,10 C13,9.44771525 12.5522847,9 12,9 L12,9 C11.4477153,9 11,9.44771525 11,10 L11,17 C11,17.5522847 11.4477153,18 12,18 Z"
  //                                   id="info" fill="#262626"
  //                                   fill-rule="nonzero"></path>
  //                               </g>
  //                             </svg>
  //                           </div>
  //                         </div>
  //                         <div className={'ticketHeaderLower'}
  //                              style={{color: 'white', display: 'flex'}}>
  //                           <div className={'rowDetails'}>
  //                             <div>Sec</div>
  //                             <div>{row.section}</div>
  //                           </div>
  //                           <div className={'rowDetails'}>
  //                             <div>Row</div>
  //                             <div>{row.row}</div>
  //                           </div>
  //                           <div className={'rowDetails'}>
  //                             <div>Seat</div>
  //                             <div>{row.seat}</div>
  //                           </div>
  //                         </div>
  //                         <div style={{color: 'white'}}>
  //                           {/*{console.log(row)}*/}
  //                           {row.gatePortalLabel}
  //                         </div>
  //                       </div>
  //                       <>
  //                         {/*<span>Section: {row.section} Row: {row.row} Seat: {row.seat}</span>*/}
  //                       </>
  //                       <BarCode ticketIndex={index} ticketObj={row.token}/>
  //                     </div>
  //
  //                   )
  //                 })}
  //                 {/*<div className="tixbcTicketBody1">a section</div>*/}
  //                 {/*<div className="tixbcTicketBody1">b section</div>*/}
  //                 {/*<div className="tixbcTicketBody1">c section</div>*/}
  //               </Slider>
  //             </div>
  //           </div>
  //           <div className={'tmdbFooter'}>
  //             <button disabled=""
  //                     className="sc-bdfBQB hzxSPn base-button__BaseButton-inDDeo kLTquS noButtons teamColorRingEffect  noFocus"
  //                     id="transferButton"
  //                     style={{background: ticketObj.c1}}
  //             >Transfer
  //             </button>
  //             <button disabled=""
  //                     className="sc-bdfBQB hzxSPn base-button__BaseButton-inDDeo kLTquS noButtons teamColorRingEffect  noFocus"
  //                     id="sellButton"
  //                     style={{background: ticketObj.c1}}
  //             >Sell
  //             </button>
  //             <div
  //                     style={{background: ticketObj.c1}}
  //                     className={'meatballbox'}
  //             >
  //               ...
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //
  //     </>
  //     }
  //
  //   </>
  // )
}


function BarCode({ticketIndex, ticketObj}) {

  let render

  const [showBarCodeButton, setshowBarCodeButton] = useState({show: true});
  const loadBarcode = () => {
    setshowBarCodeButton({show: false});
    var e = new window.Presence.SecureEntryView({});
    render = e

    e.setToken(ticketObj)
    e.setSelector(`#barcode-inner${ticketIndex}`)
  }
  useEffect(() => {
    loadBarcode()
  }, [])

  return (
    <div data-v-0140e88e="" className="v2-ticket-body">

      <div data-v-0140e88e="" className="barcode-main-container">
        <div data-v-0140e88e="" className="barcode-inner-container" on={() => alert(`page ${ticketIndex}`)}>
          <div data-v-0140e88e="" id="barcode-here">
            <div data-v-0140e88e="" id={`barcode-inner${ticketIndex}`}
                 style={{width: '100px', height: '100px'}}>{render}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App;
